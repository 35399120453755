.form-control, .form-control:focus, input{
  border-width: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

legend {
  border: none;
}

.well {
    border: none;
    box-shadow: none;
}